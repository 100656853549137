import { Nullable, request, ResponseBody } from "@tager/admin-services";

import {
  BodyInterface,
  BrandFullInterface,
  BrandInterface,
  ColorInterface,
  ComplectationFullInterface,
  ComplectationInterface,
  FullGenerationInterface,
  GenerationInterface,
  KufarBrand,
  ModelInterface,
} from "@/typings/model";
import { GenerationLandingValue } from "@/modules/generations/GenerationForm/landing/GenerationFormLanding.helpers";
import { OptionRow } from "@/modules/generations/GenerationForm/GenerationForm.helpers";

export interface CountData {
  count: number;
}

export function getBodiesList(): Promise<ResponseBody<Array<BodyInterface>>> {
  return request.get({ path: "/admin/bodies" });
}

export function getColorList(params?: {
  query?: string;
  pageNumber?: number;
  pageSize?: number;
}): Promise<ResponseBody<Array<ColorInterface>>> {
  return request.get({ path: "/admin/colors", params });
}

export function getBrandCount(): Promise<ResponseBody<CountData>> {
  return request.get({ path: "/admin/brands/count" });
}

export function getKufarData(): Promise<ResponseBody<Array<KufarBrand>>> {
  return request.get({ path: "/admin/kufar/catalog" });
}

export function getBrandList(params?: {
  query?: string;
  pageNumber?: number;
  pageSize?: number;
}): Promise<ResponseBody<Array<BrandInterface>>> {
  return request.get({ path: "/admin/brands", params });
}

export function getBrand(
  brandId: string | number
): Promise<ResponseBody<BrandFullInterface>> {
  return request.get({ path: `/admin/brands/${brandId}` });
}

export interface BrandUpdatePayload {
  displayName: string | null;
  showInCatalog: boolean;
  webHidden: boolean;
  seoRegionalDisabled: boolean;
  priority: number;
  kufarId: Nullable<string>;
  kufarName: Nullable<string>;

  logo: string | null;
  logoDark: string | null;
  logoLanding: string | null;
  logoDarkLanding: string | null;
  cover: string | null;
  coverMobile: string | null;
  title: string | null;
  textShort: string | null;
  textFull: string | null;
  warrantyText: string | null;

  stockNewApiDescription: string | null;
  stockNewPartnerDescription: string | null;
}

export function updateBrand(
  brandId: number | string,
  payload: BrandUpdatePayload
): Promise<ResponseBody<BrandFullInterface>> {
  return request.put({
    path: `/admin/brands/${brandId}`,
    body: payload,
  });
}

export function getModelCount(): Promise<ResponseBody<CountData>> {
  return request.get({ path: "/admin/models/count" });
}

export function getModelList(params?: {
  query?: string;
  pageNumber?: number;
  pageSize?: number;
  filter?: any;
}): Promise<ResponseBody<Array<ModelInterface>>> {
  return request.get({ path: "/admin/models", params });
}

export function getComplectationCount(): Promise<ResponseBody<CountData>> {
  return request.get({ path: "/admin/complectations/count" });
}

export function getComplectationList(params?: {
  query?: string;
  pageNumber?: number;
  pageSize?: number;
  filter?: any;
}): Promise<ResponseBody<Array<ComplectationInterface>>> {
  return request.get({ path: "/admin/complectations", params });
}

export function getComplectation(
  id: number
): Promise<ResponseBody<ComplectationFullInterface>> {
  return request.get({ path: `/admin/complectations/${id}` });
}

export function moveComplectation(
  id: number | string,
  direction: "up" | "down" | "up-top"
): Promise<{ success: boolean }> {
  return request.post({
    path: `/admin/complectations/${id}/move/${direction}`,
  });
}

export type ComplectationCustomSavePayload = {
  generation: number | null;
  name: string;
  subtitle: string | null;
  badge: string | null;
  image: string | null;
  engines: number[];
  doorsCount: number | null;
  seatsCount: number | null;
  trunkVolume: number | null;
  maxTrunkVolume: number | null;
  width: number | null;
  length: number | null;
  height: number | null;
  clearance: number | null;
  minWeight: number | null;
  maxWeight: number | null;
};

export type ComplectationSavePayload = {
  subtitle: string | null;
  badge: string | null;
  image: string | null;
  engines: number[];

  doorsCount: number | null;
  seatsCount: number | null;
  trunkVolume: number | null;
  maxTrunkVolume: number | null;
  width: number | null;
  length: number | null;
  height: number | null;
  clearance: number | null;
  minWeight: number | null;
  maxWeight: number | null;
};

export function deleteComplectation(
  id: number | string
): Promise<{ success: boolean }> {
  return request.delete({
    path: `/admin/complectations/${id}`,
  });
}

export function updateComplectation(
  id: number | string,
  payload: ComplectationSavePayload
): Promise<ResponseBody<ComplectationFullInterface>> {
  return request.put({
    path: `/admin/complectations/${id}`,
    body: payload,
  });
}

export function createComplectation(
  body: ComplectationSavePayload
): Promise<ResponseBody<ComplectationFullInterface>> {
  return request.post({
    path: `/admin/complectations`,
    body,
  });
}

export function getGenerationCount(): Promise<ResponseBody<CountData>> {
  return request.get({ path: "/admin/generations/count" });
}

export type GenerationParamOption = {
  param: string;
  options: Array<{
    value: string;
    label: string;
  }>;
};

export function getGenerationParamsOptions(): Promise<
  ResponseBody<Array<GenerationParamOption>>
> {
  return request.get({ path: "/admin/generations/params-options" });
}

export function getGenerationList(params?: {
  query?: string;
  pageNumber?: number;
  pageSize?: number;
}): Promise<ResponseBody<Array<GenerationInterface>>> {
  return request.get({ path: "/admin/generations", params });
}

export function getGeneration(
  generationId: string | number
): Promise<ResponseBody<FullGenerationInterface>> {
  return request.get({ path: `/admin/generations/${generationId}` });
}

export interface GenerationCreateOrUpdatePayload {
  isNew: boolean;
  name: Nullable<string>;
  model: Nullable<number>;
  slug: Nullable<string>;
  badge: Nullable<string>;
  badgeHint: Nullable<string>;
  kufarId: Nullable<string>;
  kufarName: Nullable<string>;
  images: Array<{
    color: Nullable<number>;
    images: Array<string>;
  }>;
  galleryItems: Array<{
    file: Nullable<string>;
    videoUrl: Nullable<string>;
    category: Nullable<string>;
  }>;
  reviewUrl: Nullable<string>;
  isPublic: boolean;
  publicUrl: Nullable<string>;
  deliveryDisabled: boolean;
  visibleOnBrand: boolean;
  pricesHidden: boolean;
  vinNewHidden: boolean;
  landingDisabled: boolean;
  saleDisabled: boolean;
  saleDisabledText: Nullable<string>;
  landing: GenerationLandingValue;

  previewShortDescription: string;
  previewFullDescription: string;
  previewImage: string | null;

  options: OptionRow[];

  bodies: string[];
  colors: number[];
}

export function updateGeneration(
  generationId: number | string,
  payload: GenerationCreateOrUpdatePayload
): Promise<ResponseBody<FullGenerationInterface>> {
  return request.put({
    path: `/admin/generations/${generationId}`,
    body: payload,
  });
}

export function moveGeneration(
  id: number | string,
  direction: "up" | "down" | "up-top"
): Promise<{ success: boolean }> {
  return request.post({
    path: `/admin/generations/${id}/move/${direction}`,
  });
}

export function deleteGeneration(
  id: number | string
): Promise<{ success: boolean }> {
  return request.delete({
    path: `/admin/generations/${id}`,
  });
}

export function createGeneration(
  body: GenerationCreateOrUpdatePayload
): Promise<ResponseBody<FullGenerationInterface>> {
  return request.post({
    path: `/admin/generations`,
    body,
  });
}
