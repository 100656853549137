<template>
  <ToggleSection label="Баннер">
    <FormField
      v-model:value="formValues.bannerTitle"
      name="name"
      label="Заголовок"
      @change="updateBackValues"
    />
    <FormField
      v-model:value="formValues.bannerSubtitle"
      name="subtitle"
      label="Подзаголовок"
      @change="updateBackValues"
    />

    <FormFieldFileInput
      v-model:value="formValues.bannerDesktop"
      label="Баннер - Десктоп (1360 x 450)"
      name="bannerDesktop"
      file-type="image"
      scenario="generation-landing"
      @change="updateBackValues"
    />

    <FormFieldFileInput
      v-model:value="formValues.bannerMobile"
      label="Баннер - Телефон (375 x 450)"
      name="bannerMobile"
      file-type="image"
      scenario="generation-landing"
      @change="updateBackValues"
    />

    <FormFieldFileInput
      v-model:value="formValues.bannerVideo"
      label="Баннер - Видео"
      name="bannerVideo"
      file-type="video"
      scenario="generation-landing-video"
      @change="updateBackValues"
    />

    <DynamicField :field="bannerParamsField" @change="updateBackValues" />
  </ToggleSection>

  <ToggleSection label="Основной блок">
    <FormField
      v-model:value="formValues.mainBlocksTitle"
      name="mainBlocksTitle"
      label="Заголовок"
      @change="updateBackValues"
    />

    <FormField
      v-model:value="formValues.mainBlocksText"
      name="mainBlocksText"
      label="Текст"
      type="textarea"
      @change="updateBackValues"
    />

    <DynamicField :field="mainBlocksField" @change="updateBackValues" />
  </ToggleSection>

  <ToggleSection label="Экстерьер">
    <FormField
      v-model:value="formValues.exteriorTitle"
      name="exteriorTitle"
      label="Заголовок"
      @change="updateBackValues"
    />

    <FormField
      v-model:value="formValues.exteriorText"
      name="exteriorText"
      label="Текст"
      type="textarea"
      @change="updateBackValues"
    />

    <DynamicField :field="exteriorGalleryField" @change="updateBackValues" />

    <FormField
      v-model:value="formValues.exteriorColorsTitle"
      name="exteriorColorsTitle"
      label="Заголовок блока с цветами"
      @change="updateBackValues"
    />
    <DynamicField :field="exteriorColorsField" @change="updateBackValues" />

    <FormField
      v-model:value="formValues.exteriorText2"
      name="exteriorText2"
      label="Текст 2"
      type="textarea"
      @change="updateBackValues"
    />

    <DynamicField :field="exteriorFeaturesField" @change="updateBackValues" />
  </ToggleSection>

  <ToggleSection label="Галерея">
    <FormField
      v-model:value="formValues.galleryTitle"
      name="galleryTitle"
      label="Заголовок"
      @change="updateBackValues"
    />

    <FormField
      v-model:value="formValues.galleryText"
      name="galleryText"
      label="Текст"
      type="textarea"
      @change="updateBackValues"
    />

    <DynamicField :field="galleryItemsField" @change="updateBackValues" />
  </ToggleSection>

  <ToggleSection label="Интерьер">
    <FormField
      v-model:value="formValues.interiorTitle"
      name="interiorTitle"
      label="Заголовок"
      @change="updateBackValues"
    />

    <FormField
      v-model:value="formValues.interiorText"
      name="interiorText"
      label="Текст"
      type="textarea"
      @change="updateBackValues"
    />

    <DynamicField :field="interiorGalleryField" @change="updateBackValues" />

    <FormField
      v-model:value="formValues.interiorTitle2"
      name="interiorTitle2"
      label="Заголовок - 2"
      @change="updateBackValues"
    />

    <DynamicField :field="interiorFeaturesField" @change="updateBackValues" />
  </ToggleSection>

  <ToggleSection label="О модели">
    <FormField
      v-model:value="formValues.aboutTitle"
      name="aboutTitle"
      label="Заголовок"
      @change="updateBackValues"
    />

    <FormField
      v-model:value="formValues.aboutText"
      name="aboutText"
      label="Текст"
      type="textarea"
      @change="updateBackValues"
    />

    <DynamicField :field="aboutParamsField" @change="updateBackValues" />
    <DynamicField :field="aboutGalleryField" @change="updateBackValues" />
    <DynamicField :field="aboutTextsField" @change="updateBackValues" />
    <DynamicField :field="aboutFeaturesField" @change="updateBackValues" />
  </ToggleSection>

  <ToggleSection label="Что говорят?">
    <FormField
      v-model:value="formValues.opinionsTitle"
      name="opinionsTitle"
      label="Заголовок"
      @change="updateBackValues"
    />

    <FormField
      v-model:value="formValues.opinionsText"
      name="opinionsText"
      label="Текст"
      type="textarea"
      @change="updateBackValues"
    />

    <DynamicField :field="opinionsItemsField" @change="updateBackValues" />
  </ToggleSection>

  <ToggleSection label="Фишки">
    <FormField
      v-model:value="formValues.plusesTitle"
      name="plusesTitle"
      label="Заголовок"
      @change="updateBackValues"
    />

    <FormField
      v-model:value="formValues.plusesText"
      name="plusesText"
      label="Текст"
      type="textarea"
      @change="updateBackValues"
    />

    <DynamicField :field="plusesItemsField" @change="updateBackValues" />
  </ToggleSection>

  <ToggleSection label="Безопасность">
    <FormField
      v-model:value="formValues.safetyTitle"
      name="safetyTitle"
      label="Заголовок"
      @change="updateBackValues"
    />

    <FormField
      v-model:value="formValues.safetyText"
      name="safetyText"
      label="Текст"
      type="textarea"
      @change="updateBackValues"
    />

    <FormField
      v-model:value="formValues.safetyRatingTitle"
      name="safetyRatingTitle"
      label="Рейтинг - Заголовок"
      @change="updateBackValues"
    />
    <FormField
      v-model:value="formValues.safetyRatingValue"
      name="safetyRatingValue"
      type="number"
      label="Рейтинг - Кол-во звезд"
      @change="updateBackValues"
    />

    <FormFieldFileInput
      v-model:value="formValues.safetyRatingLogos"
      label="Рейтинг - Логотипы"
      name="safetyRatingLogos"
      file-type="image"
      scenario="generation-landing"
      multiple
      @change="updateBackValues"
    />

    <FormFieldFileInput
      v-model:value="formValues.safetyRatingLogosDark"
      label="Рейтинг - Логотипы (Темная тема)"
      name="safetyRatingLogosDark"
      file-type="image"
      scenario="generation-landing"
      multiple
      @change="updateBackValues"
    />

    <FormFieldFileInput
      v-model:value="formValues.safetyImage"
      label="Картинка (1230 x 517)"
      name="safetyImage"
      file-type="image"
      scenario="generation-landing"
      @change="updateBackValues"
    />

    <FormFieldFileInput
      v-model:value="formValues.safetyImageMobile"
      label="Картинка мобильная (400 x 270)"
      name="safetyImageMobile"
      file-type="image"
      scenario="generation-landing"
      @change="updateBackValues"
    />

    <FormFieldFileInput
      v-model:value="formValues.safetyVideo"
      label="Видео - Файл"
      name="safetyVideo"
      file-type="video"
      scenario="generation-landing-video"
      @change="updateBackValues"
    />

    <FormField
      v-model:value="formValues.safetyVideoUrl"
      name="safetyVideoUrl"
      label="Видео - Ссылка"
      @change="updateBackValues"
    />

    <DynamicField :field="safetyItemsField" @change="updateBackValues" />
  </ToggleSection>

  <ToggleSection label="Гарантия">
    <FormField
      v-model:value="formValues.warrantyTitle"
      name="warrantyTitle"
      label="Заголовок"
      @change="updateBackValues"
    />

    <FormField
      v-model:value="formValues.warrantyText"
      name="warrantyText"
      label="Текст"
      type="textarea"
      @change="updateBackValues"
    />

    <FormFieldFileInput
      v-model:value="formValues.warrantyImage"
      label="Картинка (1230 x 450)"
      name="warrantyImage"
      file-type="image"
      scenario="generation-landing"
      @change="updateBackValues"
    />

    <FormFieldFileInput
      v-model:value="formValues.warrantyImageMobile"
      label="Картинка мобильная (420 х 280)"
      name="warrantyImageMobile"
      file-type="image"
      scenario="generation-landing"
      @change="updateBackValues"
    />

    <DynamicField :field="warrantyItemsField" @change="updateBackValues" />
  </ToggleSection>
</template>

<script lang="ts">
import { defineComponent, onMounted, PropType, ref } from "vue";

import { FormField, FormFieldFileInput, ToggleSection } from "@tager/admin-ui";
import { DynamicField, RepeaterField } from "@tager/admin-dynamic-field";

import {
  GenerationLandingFormValues,
  GenerationLandingValue,
} from "@/modules/generations/GenerationForm/landing/GenerationFormLanding.helpers";
import {
  convertBannerFormValuesToValue,
  convertBannerValueToFormValues,
  createBannerParamsField,
} from "@/modules/generations/GenerationForm/landing/blocks/banner";
import {
  convertMainFormValuesToValue,
  convertMainValueToFormValues,
  createMainBlocksField,
} from "@/modules/generations/GenerationForm/landing/blocks/main";
import {
  convertExteriorFormValuesToValue,
  convertExteriorValueToFormValues,
  createExteriorColorsField,
  createExteriorFeaturesField,
  createExteriorGalleryField,
} from "@/modules/generations/GenerationForm/landing/blocks/exterior";
import {
  convertInteriorFormValuesToValue,
  convertInteriorValueToFormValues,
  createInteriorFeaturesField,
  createInteriorGalleryField,
} from "@/modules/generations/GenerationForm/landing/blocks/interior";
import {
  convertAboutFormValuesToValue,
  convertAboutValueToFormValues,
  createAboutFeaturesField,
  createAboutGalleryField,
  createAboutParamsField,
  createAboutTextsField,
} from "@/modules/generations/GenerationForm/landing/blocks/about";
import {
  convertGalleryFormValuesToValue,
  convertGalleryValueToFormValues,
  createGalleryItemsField,
} from "@/modules/generations/GenerationForm/landing/blocks/gallery";
import {
  convertPlusesFormValuesToValue,
  convertPlusesValueToFormValues,
  createPlusesItemsField,
} from "@/modules/generations/GenerationForm/landing/blocks/pluses";
import {
  convertOpinionsFormValuesToValue,
  convertOpinionsValueToFormValues,
  createOpinionsItemsField,
} from "@/modules/generations/GenerationForm/landing/blocks/opinions";
import {
  convertSafetyFormValuesToValue,
  convertSafetyValueToFormValues,
  createSafetyItemsField,
} from "@/modules/generations/GenerationForm/landing/blocks/safety";
import {
  convertWarrantyFormValuesToValue,
  convertWarrantyValueToFormValues,
  createWarrantyItemsField,
} from "@/modules/generations/GenerationForm/landing/blocks/warranty";

export default defineComponent({
  name: "GenerationFormLanding",
  components: { FormFieldFileInput, FormField, DynamicField, ToggleSection },
  props: {
    value: {
      type: Object as PropType<GenerationLandingValue>,
      default: null,
    },
    data: {
      type: Object as PropType<any>,
      default: null,
    },
  },
  emits: ["update:value"],
  setup(props, context) {
    const bannerParamsField = createBannerParamsField(props.data);
    const mainBlocksField = createMainBlocksField(props.data);
    const exteriorGalleryField = createExteriorGalleryField(props.data);
    const exteriorColorsField = createExteriorColorsField(props.data);
    const exteriorFeaturesField = createExteriorFeaturesField(props.data);
    const interiorGalleryField = createInteriorGalleryField(props.data);
    const interiorFeaturesField = createInteriorFeaturesField(props.data);
    const galleryItemsField = createGalleryItemsField(props.data);
    const aboutParamsField = createAboutParamsField(props.data);
    const aboutGalleryField = createAboutGalleryField(props.data);
    const aboutTextsField = createAboutTextsField(props.data);
    const aboutFeaturesField = createAboutFeaturesField(props.data);
    const opinionsItemsField = createOpinionsItemsField(props.data);
    const plusesItemsField = createPlusesItemsField(props.data);
    const safetyItemsField = createSafetyItemsField(props.data);
    const warrantyItemsField = createWarrantyItemsField(props.data);

    const formValues = ref<GenerationLandingFormValues>({
      ...convertBannerValueToFormValues(props.data),
      ...convertMainValueToFormValues(props.data),
      ...convertExteriorValueToFormValues(props.data),
      ...convertInteriorValueToFormValues(props.data),
      ...convertAboutValueToFormValues(props.data),
      ...convertGalleryValueToFormValues(props.data),
      ...convertOpinionsValueToFormValues(props.data),
      ...convertPlusesValueToFormValues(props.data),
      ...convertSafetyValueToFormValues(props.data),
      ...convertWarrantyValueToFormValues(props.data),
    });

    const updateBackValues = () => {
      context.emit("update:value", {
        ...convertBannerFormValuesToValue(
          formValues.value,
          bannerParamsField.value as RepeaterField
        ),

        ...convertMainFormValuesToValue(
          formValues.value,
          mainBlocksField.value as RepeaterField
        ),

        ...convertExteriorFormValuesToValue(
          formValues.value,
          exteriorGalleryField.value as RepeaterField,
          exteriorColorsField.value as RepeaterField,
          exteriorFeaturesField.value as RepeaterField
        ),

        ...convertGalleryFormValuesToValue(
          formValues.value,
          galleryItemsField.value as RepeaterField
        ),

        ...convertInteriorFormValuesToValue(
          formValues.value,
          interiorGalleryField.value as RepeaterField,
          interiorFeaturesField.value as RepeaterField
        ),

        ...convertAboutFormValuesToValue(
          formValues.value,
          aboutParamsField.value as RepeaterField,
          aboutGalleryField.value as RepeaterField,
          aboutTextsField.value as RepeaterField,
          aboutFeaturesField.value as RepeaterField
        ),

        ...convertOpinionsFormValuesToValue(
          formValues.value,
          opinionsItemsField.value as RepeaterField
        ),

        ...convertPlusesFormValuesToValue(
          formValues.value,
          plusesItemsField.value as RepeaterField
        ),

        ...convertSafetyFormValuesToValue(
          formValues.value,
          safetyItemsField.value as RepeaterField
        ),

        ...convertWarrantyFormValuesToValue(
          formValues.value,
          warrantyItemsField.value as RepeaterField
        ),
      });
    };

    onMounted(updateBackValues);

    return {
      bannerParamsField,
      mainBlocksField,
      exteriorGalleryField,
      exteriorColorsField,
      exteriorFeaturesField,
      interiorGalleryField,
      interiorFeaturesField,
      galleryItemsField,
      aboutParamsField,
      aboutGalleryField,
      aboutTextsField,
      aboutFeaturesField,
      opinionsItemsField,
      plusesItemsField,
      safetyItemsField,
      warrantyItemsField,
      formValues,
      updateBackValues,
    };
  },
});
</script>

<style scoped lang="scss"></style>
